import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const analytics = {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    sendAnalyticsByIndustry: async ({rootState}, data) => {
      let request_body = JSON.parse(JSON.stringify(data.vm.request_data))
      request_body.start = moment(data.vm.dates[0]).format('YYYY-MM-DD') + 'T' + moment(data.vm.dates[0]).format('HH:mm:ss')
      request_body.end = moment(data.vm.dates[1]).format('YYYY-MM-DD') + 'T' + moment(data.vm.dates[1]).format('HH:mm:ss')

      data.vm.$http.post(`${rootState.admin_api_url}/sites/industry/csv_info`, request_body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': rootState.language,
          'Auth-Token': rootState.auth.token
        }
      })
        .then(function(response) {
          if(response.status === 200) {
            data.callback(response.body)
          }
        },this)
        .catch(function(reject) {
          data.callback('error', reject.body)
          console.log('Error in sendAnalyticsByIndustry: ', reject)
        }.bind(this))
    },
  }
}

export default analytics
