import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth'
import allusers from './modules/users'
import notificationsModule from './modules/notifications'
import sites from './modules/sites'
import tariffs from './modules/tariffs'
import analytics from './modules/analytics'
import leads from './modules/leads'
import recommendationsModule from "@/store/modules/recommendations"

import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  state: {
    admin_api_url: process.env.VUE_APP_ADMIN_API_URL,
    language: 'ru',
    user: null,
    admins_list: null,
    regex: {
      username: new RegExp(/[a-z]+|\d+|_+/g),
      email: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g)
    },
  },
  getters: {
    regex: (state) => {
      return state.regex
    },
    admin_api_url: (state) => {
      return state.admin_api_url
    },
    language: (state) => {
      return state.language
    },
    user: (state) => {
      return state.user
    },
    admins_list: (state) => {
      return state.admins_list
    },
    requestHeaders: (state) => {
      return {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': state.language,
          'Auth-Token': state.auth.token
        }
      }
    }
  },
  mutations: {
    set_user: (state, user) => {
      state.user = user
      if(user) {
        localStorage.setItem('lh-admin-panel-user', JSON.stringify(user))
      }
      else {
        localStorage.removeItem('lh-admin-panel-user')
      }
    },
    admins_list: (state, admin_list) => {
      state.admin_list = admin_list
    }
  },
  actions: {
    test: (context ) => {
      console.log(context)
    },
    logout: ({ state, commit }) => {
      commit('clear_token')
      commit('set_user', null)
      router.push({ name: 'Authorization' })
    },
    user_request: ({ state, commit }, token) => {
      Vue.http.get(`${state.admin_api_url}/users/fetch`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': state.language,
          'Auth-Token': token
        }
      })
      .then(function(response) {
        if(response.status === 200) {
          commit('set_user', response.body.user)
        }
      }.bind(this))
      .catch(function(reject) {
        console.log('Error in user_request: ', reject)
      }.bind(this))
    },
    change_password_request: ({ commit, getters }, data) => {
      let request_body = JSON.stringify({
        "password": data.password,
        "password_confirm": data.password_confirm
      })

      Vue.http.post(`${getters.admin_api_url}/users/change_password`, request_body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': getters.language,
          'Auth-Token': getters.token
        }
      })
      .then(function(response) {
        if(response.status === 200) {
          commit('auth_request', response.body.token)
        }
      }.bind(this))
      .catch(function(reject) {
        console.log('Error in change_password_request: ', reject)
      }.bind(this))
    }
  },
  modules: {
    auth: authModule,
    notifications: notificationsModule,
    users: allusers,
    sites: sites,
    tariffs: tariffs,
    analytics: analytics,
    leads: leads,
    recommendations: recommendationsModule
  }
})
