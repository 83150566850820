export default {
  install(Vue) {
    Vue.prototype.$message = function(message, type = 'success', duration = 3000 ) {
      this.$buefy.notification.open({
        duration: Number(`${duration}`),
        message: message,
        type: `is-${type}`,
        hasIcon: false
      })
    }
  }
}
