import Vue from 'vue'
import VueI18n from 'vue-i18n'
import rus from './rus.js'
import eng from './eng.js'

Vue.use(VueI18n)

const messages = {
  en: eng,
  ru: rus,
}

export default new VueI18n({
  locale: localStorage.getItem('lh-admin-panel-lang') || 'ru',
  messages,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})