import Vue from 'vue'
import router from './router'
import store from './store'
import i18n from './languages/index.js'
import VueResource from 'vue-resource'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import messagePlugin from './plugins/message.plugin'
import './filters'
import App from './App.vue'

Vue.use(VueResource)
Vue.use(Buefy)
Vue.use(messagePlugin)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
