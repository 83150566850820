import {
  NotificationProgrammatic as Notification
} from 'buefy/dist/components/notification'

const notificationsModule = {
  state: {
    notifications: []
  },
  mutations: {
    ADD_NOTIFICATION: (state, notification_config) => {
      let notification = Notification.open(notification_config)
      state.notifications.push(notification)

      setTimeout(function() {
        state.notifications.shift()
      }.bind(this), 2000)
    }
  },
}

export default notificationsModule
