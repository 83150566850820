export default {
  title: {
    home: 'Home',
    authorization: 'Authorization',
    users: 'Users',
  },
  users: {
    username: 'User name',
    enter_username: 'Enter user name',
    password: 'Password',
    enter_password: 'Enter password',
    confirm_password: 'Confirm password',
    email: 'Email',
    enter_email: 'Enter email',
    this_username_invalid: 'This user name is invalid',
    this_email_invalid: 'This email is invalid',
    re_enter_password: 'Re-enter Password',
    passwords_not_match: 'Passwords do not match',
    roles: 'Roles',
    select_role: 'Select role',
    select_roles: 'Select roles',
    sites: 'Sites',
    select_site: 'Select site',
    select_sites: 'Select sites',
    create_user: 'Create user',
    admins: 'Admins',
    create_admin: 'Create admin',
    adminname: 'Admin name',
    re_password: 'Repeat password',
  },
  show: 'Show',
  hide: 'Hide',
  add: 'Add',
  back: 'Back',
  save: 'Save',
  delete: 'Delete',
  cancel: 'Cancel',
  no_data: 'No data',
  create: 'Create',
  update: 'Update',
  download: 'Download',
  error_has_occurred: 'An error has occurred',
  period: 'Period',
  choose_period: 'Choose period',
  submit: 'Submit',
  access_rights: 'Access rights',
  choose_access_rights: 'Choose access rights',
  domain: 'Domain',
  tariff: 'Tariff',
  select_date: 'Select date',
  site_settings: {
    title: 'Site settings',
    site_on: 'Site On',
    site_off: 'Site Off',
    site_language: 'Site language',
    select_language: 'Select language',
    russian: 'Russian',
    english: 'English',
    deutsch: 'Deutsch',
    site_domain: 'Site domain',
    get_user_approvment: 'Get user approvment for data collection',
    regex_phone: 'Regular expression for phone number',
    regex_cart: 'Regex for cart total',
    webhook_url: 'Webhook URL',
    update_site_settings: 'Update site settings',
    question_update_settings: 'Do you want to update site settings?',
    no_changes: 'No changes',
    settings_updated_successfully: 'Settings updated successfully',
    upload_images: 'Upload images',
    upload_subscriptions: 'Upload subscriptions',
    parse_offers_button: 'Import YML',
    hash_site_button: 'Start calculation',
    error_hash_site_calculated: 'Error in the request to calculate the hashes of the site.',
    updated_at: 'Updated at',
    upload_yml_file_title: 'Download YML file',
    question_upload_yml: 'Are you sure you want to download YML file?',
    update_offers_settings: 'Update offers settings',
    question_update_yml: 'Are you sure you want to update the offer settings?',
    update_yml_success: 'Offer settings updated successfully',
    validate_regular_test_expression: 'Opening - "(" and closing - ")" brackets must be present',
    counter_code_connected: 'Counter code connected',
    counter_code_not_connected: 'Counter code not connected',
    order_code_connected: 'Order code connected',
    order_code_not_connected: 'Order code not connected',
    switching_site_to_new_functionality: 'Switching site to new functionality',
    process_daily_autocasts: 'Daily Trigger Mailings',
    process_lead_activity: 'Visit Trigger Mailings',
    process_lead_page_load: 'Saving visits and page views',
    save_lead_form: 'Saving of lead forms',
    widgets: 'Widgets',
    user_script: 'User script',
    forms_script: 'Script forms',
    forms_script_hint: 'The forms script is executed first, then the user script',
    site_subscription: 'Site subscription',
    subscription_included: 'Subscription included',
    subscription_disabled: 'Subscription disabled',
    mass_emails_number: 'Number of mass mailings',
    autocast_emails_count: 'Number of auto mailings',
    paid_till: 'Paid till',
    day_of_month_limit_renewal: 'Day of month limit renewal',
    subscription_updated_successfully: 'Subscription updated successfully',
    add_to_cart: 'Add to cart',
    ticker: 'Ticker',
    lead_state_update: 'Lead state update',
    lead_initialization: 'Lead initialization',
  },
  site_forms: {
    form: 'Form',
    site: 'Site',
    form_name: 'Form name',
    fields: 'Fields',
    question_delete_site_form: 'Are you sure you want to delete the site form?',
    question_update_site_form: 'Are you sure you want to update the site form?',
    update_success_message: 'Form updated successfully',
    update_error_message: 'Error updating data',
    delete_error_message: 'Error deleting form',
    create_form: 'Create form',
    form_name_length_error: 'The name of the form must be minimum 5 characters',
    create_error_message: 'Error creating form'
  },
  tariffs: {
    title: 'Tariffs',
    choose_tariff: 'Choose tariff',
  },
  analytics: {
    title: 'Analytics',
    industry_analytics: 'Industry Analytics',
    invalid_email: 'Invalid email',
    active_sites_only: 'Active sites only',
    analytics_request_error: 'Analytics request error',
    analytics_sent_to_mail_successfully: 'Analytics sent to mail successfully',
  },
  leads: {
    leads: 'Leads',
    leads_unsubscribed_from_all_mailings: 'Leads unsubscribed from all mailings',
    unsubscribe: 'Unsubscribe',
    unsubscribe_lead_from_all_mailings: 'Unsubscribe lead from all mailings',
    lead_unsubscribe_error: 'Lead unsubscribe error',
    lead_successfully_unsubscribed: 'Lead successfully unsubscribed',
    error_getting_leads_unsubscribed_from_all_mailings: 'Error getting leads unsubscribed from all mailings',
    list_of_leads_unsubscribed_from_all_mailings: 'List of leads unsubscribed from all mailings',
  },
  search: 'Search',
  recommendations: {
    title: 'recommendations',
    restart_services: 'Restart all active services',
    add_site: 'Add site'
  }
}
