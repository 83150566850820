export default {
  title: {
    home: 'Главная страница',
    authorization: 'Авторизация',
    users: 'Пользователи',
  },
  users: {
    username: 'Имя пользователя',
    enter_username: 'Введите имя пользователя',
    password: 'Пароль',
    enter_password: 'Введите пароль',
    confirm_password: 'Подтвердите пароль',
    email: 'Почта',
    enter_email: 'Введите почту',
    this_username_invalid: 'Имя пользователя некорректно',
    this_email_invalid: 'Почта некорректна',
    re_enter_password: 'Введите пароль повторно',
    passwords_not_match: 'Пароли не совпадают',
    roles: 'Роли',
    select_role: 'Выберите роль',
    select_roles: 'Выберите роли',
    sites: 'Сайты',
    select_site: 'Выберите сайт',
    select_sites: 'Выберите сайты',
    create_user: 'Создать пользователя',
    admins: 'Админы',
    create_admin: 'Создать админа',
    adminname: 'Имя админа',
    re_password: 'Пароль повторно',
  },
  show: 'Показать',
  hide: 'Скрыть',
  add: 'Добавить',
  back: 'Назад',
  save: 'Сохранить',
  delete: 'Удалить',
  cancel: 'Отмена',
  no_data: 'Данные отсутствуют',
  create: 'Создать',
  update: 'Обновить',
  download: 'Загрузить',
  error_has_occurred: 'Произошла ошибка',
  period: 'Период',
  choose_period: 'Выберите период',
  submit: 'Отправить',
  access_rights: 'Права доступа',
  choose_access_rights: 'Выберите права доступа',
  domain: 'Домен',
  tariff: 'Тариф',
  select_date: 'Выберите дату',
  mailganer_page: 'Mailganer',
  mailganer_statistic: 'Статистика',
  delete_from_stoplist_mailganer: 'Удаление из стоплиста',
  site_settings: {
    title: 'Настройки сайта',
    site_on: 'Сайт включен',
    site_off: 'Сайт выключен',
    site_language: 'Язык сайта',
    select_language: 'Выберите язык',
    russian: 'Русский',
    english: 'Английский',
    deutsch: 'Немецкий',
    site_domain: 'Домен сайта',
    get_user_approvment: 'Получать согласие пользователя на сбор данных',
    consider_order_statuses_in_statistics: 'Учитывать статусы заказов для статистики',
    regex_phone: 'Регулярное выражение для номера телефона',
    regex_cart: 'Регулярное выражение для суммы корзины',
    webhook_url: 'Url Веб-хука',
    update_site_settings: 'Обновление настроек сайта',
    question_update_settings: 'Вы уверены, что хотите обновить настройки сайта?',
    no_changes: 'Изменений нет',
    settings_updated_successfully: 'Настройки успешно обновлены',
    upload_images: 'Загрузить изображения',
    upload_subscriptions: 'Загрузить подписки',
    parse_offers_button: 'Подключить файл',
    hash_site_button: 'Начать расчет',
    error_hash_site_calculated: 'Ошибка запроса расчета хэшей сайта',
    updated_at: 'Дата обновления',
    upload_yml_file_title: 'Загрузка YML файла',
    question_upload_yml: 'Вы уверены, что хотите загрузить YML файл?',
    update_offers_settings: 'Обновление настроек офферов',
    delete_all_offers: 'Удалить все офферы',
    delete_all_goods_images: 'Удалить изображения товаров',
    deleting_all_offers: 'Удаление всех офферов',
    question_delete_all_offers: 'Вы уверены что хотите удалить все офферы?',
    question_delete_all_goods_images: 'Вы уверены что хотите удалить все изображения товаров?',
    question_update_yml: 'Вы уверены что хотите обновить настройки офферов?',
    update_yml_success: 'Настройки офферов успешно обновлены',
    delete_all_offers_success: 'Все офферы успешно удалены',
    delete_all_goods_images_success: 'Все изображения товаров успешно удалены',
    validate_regular_test_expression: 'Должны присутствовать открывающая - "(" и закрывающая - ")" скобки',
    counter_code_connected: 'Код счетчика подключен',
    counter_code_not_connected: 'Код счетчика не подключен',
    order_code_connected: 'Код заказа подключен',
    order_code_not_connected: 'Код заказа не подключен',
    switching_site_to_new_functionality: 'Переключение сайта на новый функционал',
    process_daily_autocasts: 'Ежедневные триггерные рассылки',
    process_lead_activity: 'Визитные триггерные рассылки',
    process_lead_page_load: 'Сохранение визитов и просмотров страниц',
    save_lead_form: 'Сохранение форм лида',
    widgets: 'Виджеты',
    user_script: 'Пользовательский скрипт',
    forms_script: 'Скрипт форм',
    forms_script_hint: 'Сначала исполняется скрипт форм, потом пользовательский скрипт',
    site_subscription: 'Подписка сайта',
    subscription_included: 'Подписка включена',
    subscription_disabled: 'Подписка выключена',
    expiration_alert_is_on: 'Оповещение об окончании подписки включено',
    expiration_alert_is_off: 'Оповещение об окончании подписки выключено',
    mass_emails_number: 'Количество массовых рассылок',
    autocast_emails_count: 'Количество авто рассылок',
    paid_till: 'Оплачено до',
    day_of_month_limit_renewal: 'День месяца обновления лимитов',
    days_to_alert: 'За сколько дней оповестить',
    alert_emails: 'Адреса для оповещений',
    subscription_updated_successfully: 'Подписка обновлена успешно',
    add_to_cart: 'Добавление в корзину',
    ticker: 'Тикер',
    lead_state_update: 'Обновление состояния лида',
    lead_initialization: 'Инициализация лида',
    offer_name_template_error: 'Заполните шаблон названия оффера корректно'
  },
  site_forms: {
    form: 'Форма',
    site: 'Сайт',
    form_name: 'Название формы',
    fields: 'Поля',
    question_delete_site_form: 'Вы уверены, что хотите удалить форму сайта?',
    question_update_site_form: 'Вы уверены, что хотите обновить форму сайта?',
    update_success_message: 'Форма обновленна успешно',
    update_error_message: 'Ошибка обновления данных',
    delete_error_message: 'Ошибка удаления формы',
    create_form: 'Создать форму',
    form_name_length_error: 'Название формы должно быть не меньше 5 символов',
    create_error_message: 'Ошибка создания формы',
  },
  tariffs: {
    title: 'Тарифы',
    choose_tariff: 'Выберите тариф',
  },
  analytics: {
    title: 'Аналитика',
    industry_analytics: 'Аналитика по отраслям',
    invalid_email: 'Некорректный email',
    active_sites_only: 'Только для активных сайтов',
    analytics_request_error: 'Ошибка запроса аналитики',
    analytics_sent_to_mail_successfully: 'Аналитика успешно отправлена на почту',
  },
  leads: {
    leads: 'Лиды',
    leads_unsubscribed_from_all_mailings: 'Лиды отписанные от всех рассылок',
    unsubscribe: 'Отписать',
    unsubscribe_lead_from_all_mailings: 'Отписать лида от всех рассылок',
    lead_unsubscribe_error: 'Ошибка отписки лида',
    lead_successfully_unsubscribed: 'Лид успешно отписан',
    error_getting_leads_unsubscribed_from_all_mailings: 'Ошибка получения лидов отписанных от всех рассылок',
    list_of_leads_unsubscribed_from_all_mailings: 'Список лидов отписанных от всех рассылок',
  },
  search: 'Поиск',
  recommendations: {
    title: 'Рекомендации',
    restart_services: 'Перезапустить все активные сервисы',
    add_site: 'Добавить сайт'
  }
}
