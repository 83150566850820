const leads = {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    unsubscribeLeadRequest: ({rootState, commit, getters}, data) => {
      let request_body = {
        email: data.vm.email.value
      }

      data.vm.$http.post(`${rootState.admin_api_url}/leads/unsubscribe_lead`, request_body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': rootState.language,
          'Auth-Token': rootState.auth.token
        }
      })
        .then(function(response) {
          if(response.status === 200) {
            data.callback(response.body.status)
          }
          else {
            data.callback('error', response)
          }
        },this)
        .catch(function(reject) {
          console.log('Error in unsubscribeLeadRequest: ', reject)
          data.callback('error', reject)
        }.bind(this))
    },

    getUnsubscribedLeadsRequest: ({rootState, commit, getters}, data) => {
      let params = JSON.parse(JSON.stringify(data.vm.request_params))

      data.vm.$http.get(`${rootState.admin_api_url}/leads/unsubscribe_lead`, {
        params: params,
        headers: {
          'Accept-Language': rootState.language,
          'Auth-Token': rootState.auth.token
        }
      })
        .then(function(response) {
          if(response.status === 200) {
            data.callback(response.body)
          }
        },this)
        .catch(function(reject) {
          data.callback('error', reject)
          console.log('Error in getUnsubscribedLeadsRequest: ', reject)
        }.bind(this))
    },
  }
}

export default leads
