import Vue from 'vue'
import app from '@/main'
import axios from 'axios'

const recommendationsModule = {
  namespaced: true,
  state: {
    list: [],
    loading: false,
    searchValue: ''
  },
  mutations: {
    setList: (state, list) => {
      state.list = list
    },
    setLoading: (state, loading) => {
      state.loading = loading
    },
    setSearchValue: (state, value) => {
      state.searchValue = value
    }
  },
  getters: {
    data: (state) => {
      let result

      if (state.searchValue) {
        result = state.list.filter(
          (el) => el.domain.includes(state.searchValue) || el.shop_id.includes(state.searchValue)
        )
      } else {
        result = state.list
      }

      result = result.map((el) => {
        return {
          domain: el.domain,
          serviceCount: Object.values(el.services).length,
          serviceActiveCount: Object.values(el.services).filter((el) => el.is_active).length,
          shopId: el.shop_id,
          items: Object.entries(el.services).map((s) => {
            return {
              name: s[0],
              algorithm: s[1].alg_type,
              address: s[1].addr,
              created: s[1].created_at.$date,
              active: s[1].is_active,
              shopId: el.shop_id
            }
          })
        }
      })

      return result
    }
  },
  actions: {
    CREATE_NEW_SERVICE: async ({ commit, rootState, rootGetters, dispatch }, {data, shopId}) => {
      commit('setLoading', true)

      try {
        const response = await Vue.http.post(`${rootState.admin_api_url}/recommendations/${shopId}`,
          data,
          rootGetters.requestHeaders
        )

        if (response.ok) {
          await dispatch('GET_RECOMMENDATIONS_LIST')
          app.$message(`Сервис ${data.name} запущен!`)
        }

      } catch(e) {
        commit('setLoading', false)
        let message = 'Неизвестная ошибка'

        app.$message(message, 'danger')
        console.log('CREATE_NEW_SERVICE: ', e)

      }
    },
    UPDATE_SERVICE_SETTINGS: async ({ commit, rootState, rootGetters, dispatch }, {data, shopId, serviceName}) => {
      commit('setLoading', true)
      
      try {
        const response = await Vue.http.put(`${rootState.admin_api_url}/recommendations/${shopId}/${serviceName}`,
          data,
          rootGetters.requestHeaders
        )

        if (response.status === 200 && response.body.status === 'success') {
          await dispatch('GET_RECOMMENDATIONS_LIST')
          app.$message(`Настройки сервиса ${serviceName} обновлены !`)
        }

      } catch(e) {
        commit('setLoading', false)
        let message = 'Неизвестная ошибка'

        app.$message(message, 'danger')
        console.log('UPDATE_SERVICE_SETTINGS: ', e)

      }
      

    },
    ADD_NEW_SITE: async ({ commit, rootState, rootGetters, dispatch }, {domain, shopId}) => {
      commit('setLoading', true)

      const requestBody = {
        domain: domain,
        shop_id: shopId
      }

      try {
        const response = await Vue.http.post(`${rootState.admin_api_url}/recommendations/new_client`,
          requestBody,
          rootGetters.requestHeaders
        )

        if (response.ok && response.body.status === 'success') {
          await dispatch('GET_RECOMMENDATIONS_LIST')
          app.$message('Новый клиент добавлен')
        }

        if (response.ok && response.body.status === 'error') {
          app.$message('Клиент с этим доменным именем или идентификатором уже существует', 'danger')
          commit('setLoading', false)
        }
      } catch(e) {
        commit('setLoading', false)
        let message = 'Неизвестная ошибка'

        if (e.status === 422) message = 'Не валидный URL'
        if (e.status === 404) message = 'Сайт не найден'

        app.$message(message, 'danger')
        console.log('ADD_NEW_SITE: ', e)

      }
    },
    DELETE_SITE: async ({ commit, rootState, rootGetters, dispatch }, shopId) => {
      commit('setLoading', true)

      try {
        const response = await axios.delete(`${rootState.admin_api_url}/recommendations/${shopId}`, {headers: {
          'Auth-Token': rootState.auth.token,
        }})
        
        if (response.status === 200 && response.data.status === 'success') {
          await dispatch('GET_RECOMMENDATIONS_LIST')
          commit('setLoading', false)
          app.$message('Сайт успешно удален')
        }

        if (response.ok && response.body.status === 'error') {
          app.$message('Не удалось удалить сайт', 'danger')
          commit('setLoading', false)
        }
      } catch(e) {
        commit('setLoading', false)
        let message = 'Неизвестная ошибка'

        if (e.status === 422) message = 'Не валидный URL'
        if (e.status === 404) message = 'Сайт не найден'

        app.$message(message, 'danger')
        console.log('DELETE_SITE: ', e)

      }
    },
    GET_RECOMMENDATIONS_LIST: async ({ commit, rootState, rootGetters }) => {
      commit('setLoading', true)

      try {
        const response = await Vue.http.get(`${rootState.admin_api_url}/recommendations/list`,
          rootGetters.requestHeaders
        )

        if (response.ok) {
          commit('setList', response.body.recommendation_list)
          commit('setLoading', false)
        }
      } catch (e) {
        commit('setLoading', false)
        console.log('GET_RECOMMENDATIONS_LIST: ', e)
      }
    },
    GET_SERVICE_INFO: async ({ commit, rootState, rootGetters }, {shopId, serviceName}) => {
      commit('setLoading', true)

      try {
        const response = await axios.get(`${rootState.admin_api_url}/recommendations/${shopId}/${serviceName}`, {headers: {
          'Auth-Token': rootState.auth.token,
        }})
        if(response.status === 200) {
          commit('setLoading', false)
          return response.data
        }
      } catch (e) {
        commit('setLoading', false)
        console.log('GET_SERVICE_INFO: ', e)
      }
    },
    RESTART_ALL_SERVICES: async ({ commit, rootState, rootGetters }) => {
      commit('setLoading', true)

      try {
        const response = await Vue.http.post(
          `${rootState.admin_api_url}/recommendations/restart_all_services`,
          null,
          rootGetters.requestHeaders
        )

        if (response.ok && response.body.result.status === 'success') {
          app.$message('Сервисы перезапускаются', 'info')
          commit('setLoading', false)
        }
      } catch (e) {
        commit('setLoading', false)
        console.log('RESTART_ALL_SERVICES: ', e)
      }
    },
    UPDATE_SERVICE: async ({ state, commit, rootState, rootGetters },{ serviceName, shopId, active }) => {
      commit('setLoading', true)

      const requestBody = {
        action: active ? 'stop' : 'start'
      }

      try {
        const response = await Vue.http.patch(
          `${rootState.admin_api_url}/recommendations/${shopId}/${serviceName}`,
          requestBody,
          rootGetters.requestHeaders
        )

        if (response.ok && response.body.status === 'success') {
          const updatedList = state.list.map((el) => {
            if (el.shop_id === shopId) {
              el.services[serviceName].is_active = !el.services[serviceName].is_active
            }
            return el
          })

          commit('setList', updatedList)
          commit('setLoading', false)
          app.$message(
            active ? `Сервис ${serviceName} остановлен` : `Сервис ${serviceName} запущен`
          )
        }
      } catch (e) {
        commit('setLoading', false)
        console.log('UPDATE_SERVICE: ', e)
      }
    },
    DELETE_SERVICE: async ({ state, commit, rootState, rootGetters }, { serviceName, shopId }) => {
      commit('setLoading', true)
      try {
        const response = await Vue.http.delete(
          `${rootState.admin_api_url}/recommendations/${shopId}/${serviceName}`,
            rootGetters.requestHeaders
        )

        if (response.ok && response.body.status === 'success') {
          const updatedList = state.list.map((el) => {
            if (el.shop_id === shopId) {
              delete el.services[serviceName]
            }
            return el
          })

          commit('setList', updatedList)
          commit('setLoading', false)
          app.$message(`Сервис ${serviceName} удален`)
        }
      } catch (e) {
        commit('setLoading', false)
        console.log('DELETE_SERVICE: ', e)
      }
    },
  }
}

export default recommendationsModule
