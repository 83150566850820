import Vue from 'vue'

const locale = 'ru'

Vue.filter('currency', (value, param = { currency: 'RUB', maxDigits: 2 }) => {
  if (!param.currency) param.currency = 'RUB'
  if (!param.maxDigits) param.maxDigits = 2

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    maximumFractionDigits: param.maxDigits,
    currency: param.currency
  }).format(value)
})

Vue.filter('dateTime', (value) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false
  }

  return new Intl.DateTimeFormat(locale, options).format(new Date(value))
})

Vue.filter('date', (value) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }

  return new Intl.DateTimeFormat(locale , options).format(new Date(value))
})

Vue.filter('integer', (value, useGrouping = true) => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    useGrouping
  }).format(value)
})
