import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

const MainPage = () => import('../components/MainPage')
const Home = () => import('../views/Home.vue')
const Authorization = () => import('../views/Authorization')
const Users = () => import('../views/Users')
const Sites = () => import('@/views/Sites')
const SiteSettings = () => import('@/views/SiteSettings')
const AddNewSite = () => import('@/views/AddNewSite')
const SiteForms = () => import('@/views/SiteForms')
const SiteFormEdit = () => import('@/views/SiteFormEdit')
const Tariffs = () => import('@/views/Tariffs')
const TariffEditor = () => import('@/views/TariffEditor')
const Analytics = () => import('@/views/Analytics')
const Leads = () => import('@/views/Leads')
const Recommendations = () => import('@/views/Recommendations')
const MailganerStat = () => import('@/views/MailganerStat')
const DeleteFromStoplist = () => import('@/views/DeleteFromStoplist')

import i18n from '../languages/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      redirect: { name: 'Home' },
      name: 'MainPage',
      component: MainPage,
      children: [
        {
          path: '/',
          name: 'Home',
          component: Home,
          title: 'title.home'
        },
        {
          path: '/users',
          name: 'Users',
          component: Users,
          title: 'title.users'
        },
        {
          path: '/sites',
          name: 'Sites',
          component: Sites,
          title: 'users.sites'
        },
        {
          path: '/sites/:site_id/settings',
          name: 'SiteSettings',
          component: SiteSettings,
          title: 'users.sites'
        },
        {
          path: '/sites/add_new_site',
          name: 'AddNewSite',
          component: AddNewSite,
          title: 'users.sites'
        },
        {
          path: '/sites/:site_id/forms',
          name: 'SiteForms',
          component: SiteForms,
          title: 'users.sites'
        },
        {
          path: '/sites/:site_id/forms/:form_id',
          name: 'SiteFormEdit',
          component: SiteFormEdit,
          title: 'users.sites'
        },
        {
          path: '/sites/:site_id/forms/new',
          name: 'SiteFormCreate',
          component: SiteFormEdit,
          title: 'users.sites'
        },
        {
          path: '/tariffs',
          name: 'Tariffs',
          component: Tariffs,
          title: 'tariffs.title'
        },
        {
          path: '/tariffs/new',
          name: 'TariffNew',
          component: TariffEditor,
          title: 'tariffs.title'
        },
        {
          path: '/tariffs/:tariff_id',
          name: 'TariffEdit',
          component: TariffEditor,
          title: 'tariffs.title'
        },
        {
          path: '/analytics',
          name: 'Analytics',
          component: Analytics,
          title: 'analytics.title'
        },
        {
          path: '/leads',
          name: 'Leads',
          component: Leads,
          title: 'leads.leads'
        },
        {
          path: '/recommendations',
          name: 'Recommendations',
          component: Recommendations,
          title: 'recommendations.title'
        },
        {
          path: '/mailganerstat',
          name: 'MailganerStat',
          component: MailganerStat,
          title: 'mailganer_statistic'
        },
        {
          path: '/deletefromstoplist',
          name: 'DeleteFromStoplist',
          component: DeleteFromStoplist,
          title: 'mailganer_statistic'
        }
      ]
    },
    {
      path: '/login',
      name: 'Authorization',
      component: Authorization,
      title: 'title.authorization'
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = i18n.t(to.meta.title) || 'Leadhit'

  if (to.name === 'Authorization') {
    if (localStorage.getItem('lh-admin-panel-auth-token')) {
      next({
        name: 'Home'
      })
    } else {
      next()
    }
  } else {
    if (!localStorage.getItem('lh-admin-panel-auth-token')) {
      next({
        name: 'Authorization'
      })
      store.commit('set_initial_unauthorized_path', to)
    } else {
      next()
    }
  }
})
export default router
