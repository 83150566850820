import router from '@/router'
import Vue from "vue"

const authModule = {
  state: {
    token: localStorage.getItem('lh-admin-panel-auth-token') || null,
    initial_unauthorized_path: null
  },
  mutations: {
    auth_request: (state, token) => {
      state.token = token
      localStorage.setItem('lh-admin-panel-auth-token', token)
    },
    clear_token: (state) => {
      state.token = ''
      localStorage.removeItem('lh-admin-panel-auth-token')
    },
    set_initial_unauthorized_path: (state, route) => {
      state.initial_unauthorized_path = route
    }
  },
  actions: {
    auth_request: async ({commit, rootState, dispatch}, data) => {
      let request_body = JSON.stringify({
        "username": data.user_name,
        "password": data.password
      })
      Vue.http.post(`${rootState.admin_api_url}/users/auth`, request_body, {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': rootState.language,
        }
      })
        .then(function (response) {
          if(response.status === 200) {
            dispatch('user_request', response.body.token)

            data.callback({
              message: 'Успешная авторизация',
              type: 'is-success'
            })

            commit('auth_request', response.body.token)

            if(rootState.auth.initial_unauthorized_path) {
              let initial_route = rootState.auth.initial_unauthorized_path
              commit('set_initial_unauthorized_path', null)
              router.push(initial_route)
            }
            else {
              router.push({
                name: 'Home'
              })
            }
          }
          else {
            data.callback({
              message: 'Ошибка авторизации',
              type: 'is-danger'
            })
          }
        }.bind(this))
        .catch(function(reject) {
          data.callback({
            message: 'Ошибка авторизации',
            type: 'is-danger'
          })
          console.log('Error in auth_request: ', reject)
        }.bind(this))
    },
  },
  getters: {
    token: (state) => {
      return state.token
    }
  }
}

export default authModule
