<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  created () {
    if(this.$store.getters.token) {
      if(!localStorage.getItem('lh-admin-panel-user')) {
        this.$store.dispatch('user_request', {token: this.$store.getters.token, vue: this})
      }
      else {
        try {
          this.$store.commit('set_user', JSON.parse(localStorage.getItem('lh-admin-panel-user')))
        }
        catch (error) {
          console.log('error in commit set_user', error)
        }
      }

    }
  }
}
</script>

<style lang="scss">
@import url('https://cdn.materialdesignicons.com/2.5.94/css/materialdesignicons.min.css');

html {
  overflow-y: auto;
}


.dropdown {

  .select-input {
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
  }
  .select-input::after {
    border: 3px solid #7957d5;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.625em;
    pointer-events: none;
    transform: rotate(-45deg);
    transform-origin: center;
    width: 0.625em;
    position: absolute;
    right: 15px;
  }
  .dropdown-menu {
    width: 100%;
  }
}

.field {
  margin-bottom: 0 !important;
}
.error-message {
  font-size: 12px;
  color: #ff3860;
}

article.notification {
  position: absolute;
  width: fit-content;
  z-index: 20;
  right: 30px;
}
</style>
