import * as moment from 'moment'
import logger from 'vuex/dist/logger'
import Vue from 'vue'
import app from '@/main'
import axios from 'axios'

const sites = {
  state: {
    sites: new Map(),
    form: null,
    yml: null,
    site_subscription: null,
    alerts_settings: null,
    loading_alerts_settings: false
  },
  getters: {
    alerts_settings: state => state.alerts_settings,
    allsites: state => {
      return state.sites
    },
    getSiteById: state => site_id => {
      return state.sites.get(site_id) || null
    },
    form: state => {
      return state.form
    },
    yml: state => {
      return state.yml
    },
    site_subscription: state => {
      return state.site_subscription
    }
  },
  mutations: {
    setAlertsSettings: (state, alerts_settings) => (state.alerts_settings = alerts_settings),

    setLoadingAlertsSettings: (state, loading_alerts_settings) =>
      (state.loading_alerts_settings = loading_alerts_settings),

    setSites: (state, sites) => {
      sites.forEach(function(site) {
        if (!state.sites.has(site.id)) {
          state.sites.set(site.id, site)
        }
      })
    },
    updateSiteOption: (state, data) => {
      let current_site = state.sites.get(data.site_id)
      if (current_site) {
        current_site[data.key] = data.value[data.key]
        state.sites.set(data.site_id, current_site)
      } else {
        state.sites.set(data.site_id, data.value)
      }
    },
    updateSite: (state, data) => {
      state.sites.set(data.site_id, data.value)
    },
    setSiteForm: (state, data) => {
      state.form = JSON.parse(JSON.stringify(data))
    },
    setSiteYML: (state, data) => {
      state.yml = JSON.parse(JSON.stringify(data))
    },
    setSiteNextVersFeatures: (state, data) => {
      let current_site = state.sites.get(data.site_id)
      if (!current_site) {
        current_site = {}
      }
      current_site[data.key] = data.value
      state.sites.set(data.site_id, current_site)
    },
    setSiteSubscription: (state, data) => {
      state.site_subscription = data
    }
  },
  actions: {
    startMigration: async ({ rootState }, site_id) => {
      try {
        const response = await Vue.http.get(
          `${rootState.admin_api_url}/sites/${site_id}/migration`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        if (response.status === 200) {
          app.$message('Миграция успешно запущена')
        } else app.$message('Неизвестная ошибка')
      } catch (e) {
        app.$message('Неизвестная ошибка')
      }
    },

    getAlertsSettings: async ({ rootState, commit }, site_id) => {
      commit('setLoadingAlertsSettings', true)
      try {
        const response = await Vue.http.get(
          `${rootState.admin_api_url}/sites/${site_id}/alerts_settings`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        if (response.status === 200) {
          commit('setAlertsSettings', response.body.alerts_settings)
          commit('setLoadingAlertsSettings', false)
        }
      } catch (e) {
        console.log('Error in getAlertsSettings: ', e)
        commit('setLoadingAlertsSettings', false)
      }
    },

    updateAlertsSettings: async ({ rootState, commit }, { site_id, alerts_settings }) => {
      commit('setLoadingAlertsSettings', true)
      try {
        const response = await Vue.http.patch(
          `${rootState.admin_api_url}/sites/${site_id}/alerts_settings`,
          alerts_settings,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        if (response.status === 200) {
          commit('setAlertsSettings', response.body.alerts_settings)
          commit('setLoadingAlertsSettings', false)
          app.$message('Настройки оповещений изменены')
        }
      } catch (e) {
        console.log('Error in updateAlertsSettings: ', e)
        commit('setLoadingAlertsSettings', false)
        app.$message('Ошибка при обновлении настроек оповещений', 'danger')
      }
    },

    getSite: async ({ rootState, commit }, data) => {
      let params = {
        page: data.page,
        per_page: data.per_page,
        sort_field: data.sort_field,
        sort_direction: data.sort_direction,
        search: data.search
      }
      data.vue.$http
        .get(`${rootState.admin_api_url}/sites`, {
          params: params,
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response)
            commit('setSites', response.body.sites)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in getSite: ', reject)
          }.bind(this)
        )
    },
    siteSettings: async ({ rootState, commit }, data) => {
      data.vm.$http
        .get(
          `${rootState.admin_api_url}/sites/${
            data.vm.$router.currentRoute.params.site_id
          }/settings`,
          {
            headers: {
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            if (!response.body.settings.lh_vars.hasOwnProperty('user_script')) {
              response.body.settings.lh_vars.user_script = ''
            }
            if (!response.body.settings.lh_vars.hasOwnProperty('forms_script')) {
              response.body.settings.lh_vars.forms_script = ''
            }
            response.body.settings.collect_approvement =
              response.body.settings.collect_approvement === null
                ? false
                : response.body.settings.collect_approvement

            response.body.settings.consider_order_statuses_in_statistics =
              response.body.settings.consider_order_statuses_in_statistics === null
                ? false
                : response.body.settings.consider_order_statuses_in_statistics    
            commit('updateSiteOption', {
              site_id: data.vm.$router.currentRoute.params.site_id,
              key: 'lang',
              value: response.body.settings
            })
            data.callback(response.body.settings)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in siteSettings: ', reject)
          }.bind(this)
        )
    },
    updateSiteSettings: ({ rootState, commit, getters }, data) => {
      let request_body = {}
      let settings = getters.getSiteById(data.vm.$router.currentRoute.params.site_id)

      if (settings.is_active !== data.vm.settings.is_active) {
        request_body.is_active = data.vm.settings.is_active
      }
      if (settings.language !== data.vm.settings.language) {
        request_body.language = data.vm.settings.language
      }
      if (settings.domain !== data.vm.settings.domain) {
        request_body.domain = data.vm.settings.domain
      }
      if (settings.collect_approvement !== data.vm.settings.collect_approvement) {
        request_body.collect_approvement = data.vm.settings.collect_approvement
      }
      if (settings.consider_order_statuses_in_statistics !== data.vm.settings.consider_order_statuses_in_statistics) {
        request_body.consider_order_statuses_in_statistics = data.vm.settings.consider_order_statuses_in_statistics
      }
      if (settings.phone_regex !== data.vm.settings.phone_regex) {
        request_body.phone_regex = data.vm.settings.phone_regex
      }
      if (settings.cart_regex !== data.vm.settings.cart_regex) {
        request_body.cart_regex = data.vm.settings.cart_regex
      }
      if (settings.industry !== data.vm.settings.industry) {
        request_body.industry = data.vm.settings.industry
      }
      if (settings.counter_checked !== data.vm.settings.counter_checked) {
        request_body.counter_checked = data.vm.settings.counter_checked
      }
      if (settings.order_code_checked !== data.vm.settings.order_code_checked) {
        request_body.order_code_checked = data.vm.settings.order_code_checked
      }
      if (settings.mailgun_domain !== data.vm.settings.mailgun_domain) {
        request_body.mailgun_domain = data.vm.settings.mailgun_domain
      }
      if (settings.tariff !== data.vm.settings.tariff) {
        request_body.tariff = data.vm.settings.tariff
      }
      if (settings.webhook_url !== data.vm.settings.webhook_url) {
        request_body.webhook_url = data.vm.settings.webhook_url
      }
      request_body.lh_vars = {
        user_script: data.vm.settings.lh_vars.user_script,
        forms_script: data.vm.settings.lh_vars.forms_script
      }

      data.vm.$http
        .patch(
          `${rootState.admin_api_url}/sites/${
            data.vm.$router.currentRoute.params.site_id
          }/settings`,
          request_body,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('updateSite', {
              site_id: data.vm.$router.currentRoute.params.site_id,
              value: response.body.settings
            })
            data.callback(response.body.settings)
          } else {
            data.callback('error', response)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in updateSiteSettings: ', reject)
            data.callback('error', reject)
          }.bind(this)
        )
    },
    siteIndustries: ({ rootState, commit }, data) => {
      data.vm.$http
        .get(`${rootState.admin_api_url}/sites/industry`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response.body.industries)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in siteIndustries: ', reject)
          }.bind(this)
        )
    },
    getSiteForms: ({ rootState }, obj) => {
      let params = {
        page: obj.pagination.page,
        per_page: obj.pagination.per_page,
        sort_field: obj.pagination.sort_field,
        sort_direction: obj.pagination.sort_direction,
        search: obj.pagination.search
      }

      obj.vue.$http
        .get(
          `${rootState.admin_api_url}/sites/${
            obj.vue.$router.currentRoute.params.site_id
          }/forms/list`,
          {
            params: params,
            headers: {
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            obj.callback(response.body)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in getSiteForms: ', reject)
          }.bind(this)
        )
    },
    getSiteFormInfo: ({ rootState, commit }, obj) => {
      obj.vue.$http
        .get(
          `${rootState.admin_api_url}/sites/${obj.vue.$router.currentRoute.params.site_id}/forms/${
            obj.vue.$router.currentRoute.params.form_id
          }`,
          {
            headers: {
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            let fields = JSON.parse(JSON.stringify(response.body.form.fields))
            response.body.form.fields = {}
            Array.from(Object.keys(fields)).forEach(function(key) {
              response.body.form.fields[fields[key]] = key
            })

            obj.callback(response.body.form)
            commit('setSiteForm', response.body.form)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in getSiteFormInfo: ', reject)
          }.bind(this)
        )
    },
    deleteSiteForm: ({ rootState, commit }, obj) => {
      obj.vue.$http
        .delete(
          `${rootState.admin_api_url}/sites/${obj.vue.$router.currentRoute.params.site_id}/forms/${
            obj.vue.$router.currentRoute.params.form_id
          }`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteForm', null)
            obj.callback(response)
          } else {
            obj.callback('error')
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in getSiteFormInfo: ', reject)
            obj.callback('error')
          }.bind(this)
        )
    },
    updateSiteForm: ({ rootState, commit }, obj) => {
      let request_body = {
        name: obj.vue.form.name,
        basket: obj.vue.form.basket,
        active: obj.vue.form.active,
        fields_aliases: obj.vue.form.fields_aliases,
        fields: {},
        action: obj.vue.form.action,
        banner_disabler: obj.vue.form.banner_disabler,
        basket_finale: obj.vue.form.basket_finale,
        subscription: obj.vue.form.subscription
      }
      Array.from(Object.keys(obj.vue.form.fields)).forEach(function(key) {
        request_body.fields[obj.vue.form.fields[key]] = key
      })

      obj.vue.$http
        .patch(
          `${rootState.admin_api_url}/sites/${obj.vue.$router.currentRoute.params.site_id}/forms/${
            obj.vue.$router.currentRoute.params.form_id
          }`,
          request_body,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteForm', response.body.form)
            obj.callback(response.body.form)
          } else {
            obj.callback('error', response)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in updateSiteForm: ', reject)
            obj.callback('error', reject)
          }.bind(this)
        )
    },
    createSiteForm: ({ rootState, commit }, obj) => {
      let request_body = JSON.parse(JSON.stringify(obj.vue.form))
      request_body.fields = {}
      Array.from(Object.keys(obj.vue.form.fields)).forEach(function(key) {
        request_body.fields[obj.vue.form.fields[key]] = key
      })

      obj.vue.$http
        .post(
          `${rootState.admin_api_url}/sites/${obj.vue.$router.currentRoute.params.site_id}/forms`,
          request_body,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            obj.callback(response)
          } else {
            obj.callback('error')
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in createSiteForm: ', reject)
            obj.callback('error')
          }.bind(this)
        )
    },
    siteYML: ({ rootState, commit }, obj) => {
      obj.vm.$http
        .get(`${rootState.admin_api_url}/sites/${obj.vm.$router.currentRoute.params.site_id}/yml`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteYML', response.body.yml)
            obj.callback(JSON.parse(JSON.stringify(response.body.yml)))
            if(response.body.yml.status === 'error') {
              app.$message('Ошибка при загрузке фида.\nДля подробностей нажмите кнопку “Показать ошибки”', 'danger', 60000)
            }
            
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in siteYML: ', reject)
          }.bind(this)
        )
    },
    parseSiteOffers: ({ rootState, commit }, obj) => {
      let request_body = JSON.parse(JSON.stringify(obj.vm.yml_request))

      obj.vm.$http
        .post(`${rootState.admin_api_url}/sites/offers/parse`, request_body, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            obj.callback()
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in parseSiteOffers: ', reject)
          }.bind(this)
        )
    },
    updateSiteYML: ({ rootState, commit }, obj) => {
      let request_body = {
        url: obj.vm.offers_settings.url,
        significant_params: obj.vm.offers_settings.significant_params,
        renew_offers: obj.vm.offers_settings.renew_offers,
        offer_regex: obj.vm.offers_settings.offer_regex,
        offer_picture_dimensions: {
          width: parseInt(obj.vm.offers_settings.offer_picture_dimensions.width),
          height: parseInt(obj.vm.offers_settings.offer_picture_dimensions.height)
        },
        chunked_parsing: obj.vm.offers_settings.chunked_parsing,
        upload_images: obj.vm.offers_settings.upload_images,
        parse_start_hour: obj.vm.offers_settings.parse_start_hour,
        offer_name_template: obj.vm.offers_settings.offer_name_template,
      }

      obj.vm.$http
        .patch(
          `${rootState.admin_api_url}/sites/${obj.vm.$router.currentRoute.params.site_id}/yml`,
          request_body,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteYML', response.body.yml)
            obj.callback(response.body.yml)
          } else {
            obj.callback('error', response)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in updateSiteYML: ', reject)
            obj.callback('error', reject)
          }.bind(this)
        )
    },
    
    deleteAllOffers:({rootState, state}) => {
      return new Promise((resolve) => {
        let site_id = state.yml.site
        axios.delete(`${rootState.admin_api_url}/sites/offers/${site_id}`, {headers: {
          'Auth-Token': rootState.auth.token,
        }})
        .then(response => resolve(response.status))
        .catch(e => console.log(e))
      })
     
    },
    deleteAllGoodsImages: ({rootState, state}) =>  {
      return new Promise((resolve) => {
        let site_id = state.yml.site
        axios.delete(`${rootState.admin_api_url}/sites/offers/${site_id}/pictures`, {headers: {
          'Auth-Token': rootState.auth.token,
        }})
        .then(response => resolve(response.status))
        .catch(e => console.log(e))
      })
    },

    testOffersRegular: ({ rootState, commit }, obj) => {
      let request_body = JSON.parse(JSON.stringify(obj.vm.test_regular))

      obj.vm.$http
        .post(`${rootState.admin_api_url}/tools/test_regular`, request_body, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            obj.callback(response.body)
          } else {
            obj.callback('error')
          }
        }, this)
        .catch(
          function(reject) {
            obj.callback('error')
            console.log('Error in testOffersRegular: ', reject)
          }.bind(this)
        )
    },

    getSiteNextVersFeatures: async ({ rootState, commit }, data) => {
      data.vm.$http
        .get(
          `${rootState.admin_api_url}/sites/${
            data.vm.$router.currentRoute.params.site_id
          }/next_vers_features`,
          {
            headers: {
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteNextVersFeatures', {
              value: response.body.next_vers_features,
              key: 'next_vers_features',
              site_id: data.vm.$router.currentRoute.params.site_id
            })
            data.callback(response.body.next_vers_features)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in getSiteNextVersFeatures: ', reject)
          }.bind(this)
        )
    },
    updateNextVersFeatures: ({ rootState, commit, getters }, data) => {
      let request_body = {}
      let next_vers_features = getters.getSiteById(data.vm.$router.currentRoute.params.site_id)
        .next_vers_features

      if (
        next_vers_features.process_daily_autocasts !==
        data.vm.site_next_vers_features.process_daily_autocasts
      ) {
        request_body.process_daily_autocasts =
          data.vm.site_next_vers_features.process_daily_autocasts
      }
      if (
        next_vers_features.process_lead_activity !==
        data.vm.site_next_vers_features.process_lead_activity
      ) {
        request_body.process_lead_activity = data.vm.site_next_vers_features.process_lead_activity
      }
      if (
        next_vers_features.process_lead_page_load !==
        data.vm.site_next_vers_features.process_lead_page_load
      ) {
        request_body.process_lead_page_load = data.vm.site_next_vers_features.process_lead_page_load
      }
      if (next_vers_features.save_lead_form !== data.vm.site_next_vers_features.save_lead_form) {
        request_body.save_lead_form = data.vm.site_next_vers_features.save_lead_form
      }
      if (next_vers_features.widgets !== data.vm.site_next_vers_features.widgets) {
        request_body.widgets = data.vm.site_next_vers_features.widgets
      }
      if (next_vers_features.add_to_cart !== data.vm.site_next_vers_features.add_to_cart) {
        request_body.add_to_cart = data.vm.site_next_vers_features.add_to_cart
      }
      if (next_vers_features.tick !== data.vm.site_next_vers_features.tick) {
        request_body.tick = data.vm.site_next_vers_features.tick
      }
      if (next_vers_features.lead_state !== data.vm.site_next_vers_features.lead_state) {
        request_body.lead_state = data.vm.site_next_vers_features.lead_state
      }
      if (next_vers_features.initialize !== data.vm.site_next_vers_features.initialize) {
        request_body.initialize = data.vm.site_next_vers_features.initialize
      }

      data.vm.$http
        .patch(
          `${rootState.admin_api_url}/sites/${
            data.vm.$router.currentRoute.params.site_id
          }/next_vers_features`,
          request_body,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteNextVersFeatures', {
              value: response.body.next_vers_features,
              key: 'next_vers_features',
              site_id: data.vm.$router.currentRoute.params.site_id
            })
            data.callback(response.body.next_vers_features)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in updateNextVersFeatures: ', reject)
          }.bind(this)
        )
    },

    getSiteSubscription: async ({ rootState, commit }, site_id) => {
      try {
        const response = await Vue.http.get(
          `${rootState.admin_api_url}/sites/${site_id}/subscription`,
          {
            headers: {
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )

        if (response.status === 200) {
          commit('setSiteSubscription', response.body.subscription)
        }
      } catch (e) {
        if (e.status === 404) {
          app.$message('Подписка не найдена', 'danger')
        } else {
          console.log('Error in getSiteSubscription: ', e)
        }
      }
    },
    createSiteSubscription: async ({ rootState, commit }, { site_id, subscription }) => {
      subscription.paid_till =
        moment(subscription.paid_till).format('YYYY-MM-DD') +
        'T' +
        moment(subscription.paid_till).format('HH:mm:ss')

      try {
        const response = await Vue.http.post(
          `${rootState.admin_api_url}/sites/${site_id}/subscription`,
          subscription,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )

        if (response.status === 200) {
          commit('setSiteSubscription', response.body.subscription)
          app.$message('Подписка успешно создана')
        }
      } catch (e) {
        app.$message('Ошибка при создании подписки', 'danger')
        console.log('Error in getSiteSubscription: ', e)
      }
    },
    updateSiteSubscription: ({ rootState, commit }, obj) => {

      let alertEmailsArray

      if(obj.vue.site_subscription.alert_emails) {
        if(obj.vue.site_subscription.test_alert_emails.test(obj.vue.site_subscription.alert_emails)) {
          alertEmailsArray = obj.vue.site_subscription.alert_emails.split(', ')
        } 
        
        
      }

      let request_body = {
        limits: {
          autocast_emails_count: obj.vue.site_subscription.limits.autocast_emails_count,
          mass_emails_count: obj.vue.site_subscription.limits.mass_emails_count
        },
        is_active: obj.vue.site_subscription.is_active,
        expiration_alert: obj.vue.site_subscription.expiration_alert,
        expiration_alert_trigger: Number(obj.vue.site_subscription.expiration_alert_trigger),
        alert_emails: alertEmailsArray, 
        paid_till:
          moment(obj.vue.site_subscription.paid_till).format('YYYY-MM-DD') +
          'T' +
          moment(obj.vue.site_subscription.paid_till).format('HH:mm:ss'),
        renew_limits_at: obj.vue.site_subscription.renew_limits_at
      }


      obj.vue.$http
        .patch(
          `${rootState.admin_api_url}/sites/${
            obj.vue.$router.currentRoute.params.site_id
          }/subscription`,
          request_body,
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            commit('setSiteSubscription', response.body.subscription)
            obj.callback(response.body.subscription)
          } else {
            obj.callback('error', response)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in updateSiteSubscription: ', reject)
            obj.callback('error', reject)
          }.bind(this)
        )
    },
    calculationHashSite: ({ rootState }, data) => {
      const request_body = {
        site_id: data.vue.$router.currentRoute.params.site_id
      }
      data.vue.$http
        .post(`${rootState.admin_api_url}/sites/set_visited_pages_hashes`, request_body, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(response => {
          if (response.status === 200) {
            data.callback(response)
          }
        })
        .catch(err => {
          console.log('Error in calculationHashSite: ', err)
          data.callback('error')
        })
    }
  }
}

export default sites
