import router from '@/router'

const allusers = {
  state: {
    all_users: null,
    all_admin: null,
    users_permissions: []
  },

  getters: {
    all_users: state => {
      return state.all_users
    },
    all_admin: state => {
      return state.all_admin
    },
    users_permissions: state => {
      return state.users_permissions
    }
  },
  mutations: {
    response_all_user: (state, users) => {
      state.all_users = users
    },
    response_all_admin: (state, admin) => {
      state.all_admin = admin
    },
    set_users_permissions: (state, permissions) => {
      state.users_permissions = permissions
    }
  },
  actions: {
    get_users_permissions: async ({ rootState, commit }, data) => {
      data.vm.$http
        .get(`${rootState.admin_api_url}/users/permissions`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            commit('set_users_permissions', response.body.permissions)
            data.callback('succes', response.body)
          }
        }, this)
        .catch(
          function(reject) {
            data.callback('error', reject)
            console.log('Error in get_users_permissions: ', reject)
          }.bind(this)
        )
    },

    dell_user: async ({ commit, rootState }, data) => {
      data.vue.$http
        .delete(`${rootState.admin_api_url}/users/control-panel/${data.id}`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(data)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
            console.log('Error in getAllUsers: ', reject)
          }.bind(this)
        )
    },
    get_users: async ({ rootState }, data) => {
      data.vue.$http
        .get(`${rootState.admin_api_url}/users/control-panel/?`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback({
              response: response.body.users
            })
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
            console.log('Error in getAllUsers: ', reject)
          }.bind(this)
        )
    },
    edit_user_request: async ({ commit, rootState }, data) => {
      let request_body = {
        username: data.username,
        email: data.email,
        roles: data.roles,
        permissions: data.permissions,
        sites: data.sites,
        owner: data.owner,
        active: data.active
      }
      if (data.password) {
        request_body.password = data.password
      }

      data.vue.$http
        .patch(
          `${rootState.admin_api_url}/users/control-panel/${data.getParamsId}`,
          JSON.stringify(request_body),
          {
            headers: {
              'Content-Type': 'application/json',
              'Accept-Language': rootState.language,
              'Auth-Token': rootState.auth.token
            }
          }
        )
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response)
          } else {
            data.callbackerror(response.body.message)
            console.log('response error in requestUpdatePassword', response)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
            console.log('Error in requestUpdatePassword: ', reject)
          }.bind(this)
        )
        .finally(function() {}.bind(this))
    },
    create_user_request: async ({ commit, rootState }, data) => {
      var request_body = {
        username: data.username,
        password: data.password,
        email: data.email,
        roles: data.roles,
        permissions: data.permissions,
        sites: data.sites,
        tariffs: data.tariffs
      }

      if (data.identifyOwner.active) {
        request_body.owner = data.owner
      }

      data.vue.$http
        .post(`${rootState.admin_api_url}/users/control-panel`, JSON.stringify(request_body), {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response)
          } else {
            console.log('response error in requestUpdatePassword', response)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
            console.log('Error in requestUpdatePassword: ', reject)
          }.bind(this)
        )
    },

    getAdmins: async ({ rootState }, data) => {
      data.vue.$http
        .get(`${rootState.admin_api_url}/users/`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback({
              response: response
            })
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
          }.bind(this)
        )
    },
    dellAdmin: async ({ commit, rootState }, data) => {
      data.vue.$http
        .delete(`${rootState.admin_api_url}/users/${data.data.id}`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(data)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
          }.bind(this)
        )
    },
    createAdmin: async ({ commit, rootState }, data) => {
      var request_body = JSON.stringify({
        username: data.data.name,
        password: data.data.password,
        email: data.data.email
      })

      data.vue.$http
        .post(`${rootState.admin_api_url}/users/`, request_body, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
          }.bind(this)
        )
        .finally(function() {}.bind(this))
    },
    updateAdmin: async ({ commit, rootState }, data) => {
      let request_body

      if (data.data.password_change) {
        request_body = JSON.stringify({
          username: data.data.name,
          password: data.data.password,
          email: data.data.email,
          active: data.data.active
        })
      } else {
        request_body = JSON.stringify({
          username: data.data.name,
          email: data.data.email,
          active: data.data.active
        })
      }

      data.vue.$http
        .patch(`${rootState.admin_api_url}/users/${data.data.id}`, request_body, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
          }.bind(this)
        )
        .finally(function() {}.bind(this))
    },
    getAuthKey: async ({ rootState }, data) => {
      data.vue.$http
        .get(`${rootState.admin_api_url}/users/control-panel/${data.id}/auth-key`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            data.callback(response)
          }
        }, this)
        .catch(
          function(reject) {
            data.callbackerror(reject.body.message)
          }.bind(this)
        )
    }
  }
}

export default allusers
