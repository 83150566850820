import Vue from 'vue'
import app from '@/main'
import router from '@/router'
import * as moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const tariffs = {
  state: {
    tariffs: null,
    tariffs_pagination: null,
    tariffs_plain: null,
    default_instruments: null
  },

  getters: {
    tariffs: state => {
      return state.tariffs
    },
    get_tariff_by_id: state => tariff_id => {
      return state[tariff_id]
    },
    get_tariffs_pagination: state => {
      return state.tariffs_pagination
    },
    get_tariffs_plain: state => {
      return state.tariffs_plain
    },
    default_instruments: state => state.default_instruments
  },
  mutations: {
    set_tariffs: (state, tariffs) => {
      state.tariffs = tariffs
    },
    set_pagination: (state, pagination) => {
      state.tariffs_pagination = pagination
    },
    set_tariff: (state, tariff) => {
      state[tariff.id] = tariff
    },
    delete_tariff_by_id: (state, tariff_id) => {
      state.tariffs = state.tariffs.filter(function(tariff) {
        if (tariff.id === tariff_id) {
          return false
        } else {
          return true
        }
      })
    },
    add_new_tariff: (state, tariff) => {
      state.tariffs.push(tariff)
    },
    reset_tariffs: state => {
      state.tariffs = null
    },
    set_tariffs_plain: (state, tariffs) => {
      state.tariffs_plain = tariffs
    },
    set_default_instruments: (state, default_instruments) => {
      state.default_instruments = default_instruments
    }
  },
  actions: {
    get_default_instruments: async ({ rootState, commit, rootGetters }) => {
      try {
        const response = await Vue.http.get(
          `${rootState.admin_api_url}/tariffs/default_instruments`,
          {
            headers: {
              'Accept-Language': rootState.language,
              'Auth-Token': rootGetters.token
            }
          }
        )

        if (response.status === 200 && response.body.default_instruments) {
          commit('set_default_instruments', response.body.default_instruments)
        } else {
          console.log('Error in get_default_instruments: ', response)
        }
      } catch (e) {
        console.log('Error in get_default_instruments: ', e)
      }
    },
    tariffs: async ({ rootState, commit, dispatch }, data) => {
      let params = JSON.parse(JSON.stringify(data.vm.request_params))

      try {
        const response = await Vue.http.get(`${rootState.admin_api_url}/tariffs`, {
          params: params,
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })

        if (response.status === 200) {
          if (data.callback) data.callback(response.body)
          if (params.search) {
            response.body.pagination.search = params.search
          }
          commit('set_tariffs', response.body.tariffs)
          commit('set_pagination', response.body.pagination)
        }
      } catch (e) {
        if (data.callback) data.callback('error', e.body)
        console.log('Error in tariffs: ', e)
      }
    },
    get_tariff: async ({ rootState, commit, dispatch }, tariff_id) => {
      try {
        const response = await Vue.http.get(`${rootState.admin_api_url}/tariffs/${tariff_id}`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })

        if (response.status === 200) {
          commit('set_tariff', JSON.parse(JSON.stringify(response.body.tariff)))
          return response.body.tariff
        }
      } catch (e) {
        console.log('Error in get_tariff: ', e)
        app.$message('Ошибка получения данных', 'danger')
      }
    },
    new_tariff: async ({ rootState, commit }, tariff) => {
      try {
        const response = await Vue.http.post(`${rootState.admin_api_url}/tariffs`, tariff, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })

        if (response.status === 200) {
          commit('reset_tariffs')
          commit('set_tariff', response.body.tariff)
          router.replace({
            name: 'TariffEdit',
            params: { tariff_id: response.body.tariff.id }
          })
          app.$message('Тариф успешно создан')
          return response.body.tariff
        }
      } catch (e) {
        console.log('Error in new_tariff: ', e)
        app.$message(e.message ? response.e : 'Ошибка при создании тарифа', 'danger')
      }
    },
    update_tariff: async ({ rootState, commit }, { body, id }) => {
      try {
        const response = await Vue.http.patch(`${rootState.admin_api_url}/tariffs/${id}`, body, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })

        if (response.status === 200) {
          commit('set_tariff', JSON.parse(JSON.stringify(response.body.tariff)))
          commit('reset_tariffs')
          app.$message('Тариф успешно обновлен')
        }
      } catch (e) {
        console.log('Error in update_tariff: ', e)
        app.$message(e.message ? e.message : 'Ошибка обновления данных', 'danger')
      }
    },
    delete_tariff: async ({ commit, rootState }, data) => {
      data.vm.$http
        .delete(`${rootState.admin_api_url}/tariffs/${data.vm.deleting_id}`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            commit('delete_tariff_by_id', data.vm.deleting_id)
            data.callback('success')
          }
        }, this)
        .catch(
          function(reject) {
            data.callback('error', reject)
            console.log('Error delete_tariff: ', reject)
          }.bind(this)
        )
    },
    getAllTariffs: async ({ rootState, commit }, data) => {
      data.vm.$http
        .get(`${rootState.admin_api_url}/tariffs/list/plain`, {
          headers: {
            'Accept-Language': rootState.language,
            'Auth-Token': rootState.auth.token
          }
        })
        .then(function(response) {
          if (response.status === 200) {
            commit('set_tariffs_plain', response.body.tariffs)
          }
        }, this)
        .catch(
          function(reject) {
            console.log('Error in getAllTariffs: ', reject)
          }.bind(this)
        )
    }
  }
}

export default tariffs
